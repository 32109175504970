import * as React from "react";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";
import Text from "../../components/global/text";
import Develop from "../../components/global/develop";
import Promise from "../../components/global/promise";
import HeaderGlobal from "../../components/headers/header-global";
import { graphql } from "gatsby";
import PromiseOne from "../../assets/images/other-services/experts.svg";
import PromiseTwo from "../../assets/images/other-services/secure-app.svg";
import PromiseThree from "../../assets/images/other-services/quality-control.svg";
import WeOffer from "../../components/app-development/we-offer";
import Offer from "../../components/global/offer";

import PrivateImg from "../../assets/images/other-services/private-blockchain.svg";
import ConsultingImg from "../../assets/images/other-services/blockchain-technology-consulting.svg";
import IcoImg from "../../assets/images/other-services/ico-ieo-and-sto.svg";
import DappsImg from "../../assets/images/other-services/dapps-development.svg";
import NftsImg from "../../assets/images/other-services/nfts.svg";
import IotImg from "../../assets/images/other-services/blockchain-with-iot.svg";
import AiImg from "../../assets/images/other-services/blockchain-in-ai.svg";
import NftsTokensImg from "../../assets/images/other-services/nft-tokens.svg";
import EthereumImg from "../../assets/images/other-services/ethereum.png";
import ExonumImg from "../../assets/images/other-services/exonum.png";
import HyperledgerImg from "../../assets/images/other-services/hyperledger.png";
import HeaderBcg from "../../assets/images/headers/blockchain.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Icons from "../../components/homepage/icons";
// Text Component

const ApplicationDevelopment = () => {
  const { t } = useTranslation();

  // Header Content
  const hTitle = `${t("services.blockchain.headline")}`;
  const hTextOne = `${t("services.blockchain.firstText")}`;

  const promiseHeadline = `${t("services.blockchain.promise.headline")}`;

  // Promise Component
  const promiseContent = [
    {
      title: `${t("services.blockchain.promise.first.title")}`,
      textOne: `${t("services.blockchain.promise.first.firstText")}`,
      icon: <PromiseOne />,
    },
    {
      title: `${t("services.blockchain.promise.second.title")}`,
      textOne: `${t("services.blockchain.promise.second.firstText")}`,
      icon: <PromiseTwo />,
    },
    {
      title: `${t("services.blockchain.promise.third.title")}`,
      textOne: `${t("services.blockchain.promise.third.firstText")}`,
      icon: <PromiseThree />,
    },
  ];

  const firstWeOfferContent = {
    title: `${t("services.blockchain.firstOffer.headline")}`,
    firstText: `${t("services.blockchain.firstOffer.firstText")}`,
    secondText: `${t("services.blockchain.firstOffer.secondText")}`,
  };

  const secondWeOfferContent = {
    title: `${t("services.blockchain.secondOffer.headline")}`,
    firstText: `${t("services.blockchain.secondOffer.firstText")}`,
    secondText: `${t("services.blockchain.secondOffer.secondText")}`,
    thirdText: `${t("services.blockchain.secondOffer.thirdText")}`,
  };

  // Offer Component
  const offerContent = [
    {
      icon: <PrivateImg />,
      title: `${t("services.blockchain.offerCards.first.title")}`,
      text: `${t("services.blockchain.offerCards.first.text")}`,
    },
    {
      icon: <ConsultingImg />,
      title: `${t("services.blockchain.offerCards.second.title")}`,
      text: `${t("services.blockchain.offerCards.second.text")}`,
    },
    {
      icon: <IcoImg />,
      title: `${t("services.blockchain.offerCards.third.title")}`,
      text: `${t("services.blockchain.offerCards.third.text")}`,
    },
    {
      icon: <DappsImg />,
      title: `${t("services.blockchain.offerCards.fourth.title")}`,
      text: `${t("services.blockchain.offerCards.fourth.text")}`,
    },
    {
      icon: <NftsImg />,
      title: `${t("services.blockchain.offerCards.fifth.title")}`,
      text: `${t("services.blockchain.offerCards.fifth.text")}`,
    },
    {
      icon: <IotImg />,
      title: `${t("services.blockchain.offerCards.sixth.title")}`,
      text: `${t("services.blockchain.offerCards.sixth.text")}`,
    },
    {
      icon: <AiImg />,
      title: `${t("services.blockchain.offerCards.seventh.title")}`,
      text: `${t("services.blockchain.offerCards.seventh.text")}`,
    },
  ];

  const textContent2 = {
    title: `${t("services.blockchain.icons.headline")}`,
    text: `${t("services.blockchain.icons.text")}`,
  };

  const iconsData = [
    {
      icon: <NftsTokensImg />,
      title: `${t("services.blockchain.icons.cards.first.headline")}`,
      text: `${t("services.blockchain.icons.cards.first.text")}`,
    },
    {
      icon: <NftsTokensImg />,
      title: `${t("services.blockchain.icons.cards.second.headline")}`,
      text: `${t("services.blockchain.icons.cards.second.text")}`,
    },
    {
      icon: <NftsTokensImg />,
      title: `${t("services.blockchain.icons.cards.third.headline")}`,
      text: `${t("services.blockchain.icons.cards.third.text")}`,
    },
    {
      icon: <NftsTokensImg />,
      title: `${t("services.blockchain.icons.cards.fourth.headline")}`,
      text: `${t("services.blockchain.icons.cards.fourth.text")}`,
    },
  ];

  return (
    <Layout>
      <Seo title={t("navigation.services.blockchain")} />
      <HeaderGlobal hTitle={hTitle} hTextOne={hTextOne} image={HeaderBcg} />
      <Promise
        promiseContent={promiseContent}
        promiseHeadline={promiseHeadline}
        differentStyle="-blockchain -appDev"
      />
      <WeOffer weOfferContent={firstWeOfferContent} />
      <Offer offerContent={offerContent} cssModifer="-blockchain" />
      <section className="m-blockchain section-padding">
        <div className="_wr">
          <h2 className="m-blockchain__title">
            {t("services.blockchain.sectionTitle")}
          </h2>
          <span className="a-line -red"></span>
          <div className="_w mt-40 m-blockchain__technologies">
            <div className="_m4 m-blockchain__technology">
              <img src={EthereumImg} alt="Ethereum" />
            </div>
            <div className="_m4 m-blockchain__technology">
              <img src={ExonumImg} alt="Exonum" />
            </div>
            <div className="_m4 m-blockchain__technology">
              <img src={HyperledgerImg} alt="Hyperledger" />
            </div>
          </div>
        </div>
      </section>
      <WeOffer weOfferContent={secondWeOfferContent} />
      <Text textContent={textContent2} cssModifer="-white -token" />
      <Icons iconsData={iconsData} cssModifer="-white" />
      <Develop />
    </Layout>
  );
};

export default ApplicationDevelopment;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
