import React from "react";
import "swiper/css";

const Icons = ({ iconsData, cssModifer }) => {
  const cardContent = (title, icon, text) => {
    return (
      <div className="m-icons__card">
        <div className="m-icons__card--iconTitle">
          <div className="m-icons__card--icon">{icon}</div>
          <h3 className="m-icons__card--title">{title}</h3>
        </div>
        <p className="m-icons__card--text">{text}</p>
      </div>
    );
  };

  return (
    <section className={`m-icons ${cssModifer ? cssModifer : ""}`}>
      <div className="_wr">
        <div className="_w">
          {iconsData.map(({ title, icon, text }, i) => (
            <div className="_12 _l6 m-icons__cardCol" key={i}>
              {cardContent(title, icon, text)}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Icons;
